import React, { useState, useEffect} from 'react';
import { 
    Grow, 
    Button, 
    CssBaseline, 
    TextField, 
    FormControlLabel, 
    Grid, 
    Box, 
    Typography, 
    Container,
    Switch,
    Divider,
} from '@mui/material';
import { AdapterDateFns } from '@mui/x-date-pickers/AdapterDateFns';
import { LocalizationProvider, DatePicker } from '@mui/x-date-pickers';
import { useTheme, ThemeProvider } from '@mui/material/styles';
import CIcon from '@coreui/icons-react';
import * as icon from '@coreui/icons';
import Logo from '../../assets/Logo';
import ProfilePicture from '../ProfilePicture/ProfilePicture';
import axios from 'axios';
import DialogSports from '../Dialog/Dialog';

export default function SignUp(props) {
    const [name, setName] = useState('');
    const [lastName, setLastName] = useState('');
    const [email, setEmail] = useState('');
    const [password, setPassword] = useState('');
    const [previousExperience, setPreviousExperience] = useState('');
    const [phoneNumber, setPhoneNumber] = useState('');
    const [birthday, setBirthday] = useState('');
    const [pictureURL, setPictureURL] = useState('');
    const [userID, setUserID] = useState('');
    const [userType, setUserType] = useState('');
    const theme = useTheme();
    const [dialogProps, setDialogProps] = useState({ title: '', content: '', leftButton: '', rightButton: '', open: false });

    const handleDialogLeftButton = () => {
        setDialogProps(
            { 
                title: '', 
                content: '', 
                leftButton: '', 
                rightButton: '', 
                open: false
            }
        );

        if (userID) {
            props.setView('today');
            props.setUserType(userType);
        }
    }
    
    const handleSubmit = async (event) => {
        event.preventDefault();
        const data = new FormData(event.currentTarget);
        let payload = {
            "firstName" : data.get('name'),
            "lastName" : data.get('lastname'),
            "email" : data.get('email'),
            "password" : data.get('password'),
            "phoneNumber" : data.get('phonenumber'),
            "birthday" : birthday,
            "previousExperience" : data.get('previousExperience'),
            "pictureURL" : pictureURL
        };
        
        if (
            payload.name != '' &&
            payload.lastname != '' &&
            payload.email != '' &&
            payload.password != '' &&
            payload.phoneNumber != '' &&
            payload.birthday != '' &&
            payload.previousExperience != ''
        ) {
            await axios.post(`${process.env.REACT_APP_ENV == 'dev' ? process.env.REACT_APP_BLL_URL : ''}/user/create`, payload)
            .then((res) => {
                // props.setPhoneToVerify(`+52${payload.phoneNumber}`);
                setUserID(res.data.newUser._id);
                // Set logguedIn user
                let encodedUser = JSON.stringify(res.data.newUser);
                localStorage.setItem('LoggedInUser', encodedUser);
                props.setLogguedInUser(res.data.newUser);
                res.data.newUser.type === 'admin' && props.setAdmin(true);
                setUserType(res.data.newUser.type);
                setDialogProps({title: '¡Felicidades 🥳!', content: 'Tu cuenta se ha creada correctamente, después de dar click en Ok iniciarás sesión', leftButton: 'Ok', rightButton: '', open: true});
            })
            .catch((err) => {
                if (err.response.data && err.response.data.errorMsg.code == 11000) {
                    setDialogProps({title: 'Alerta ⚠️', content: 'Parece que ya existe una cuenta creada para este correo y/o número de teléfono, por favor intenta iniciar sesión', leftButton: 'Ok', rightButton: '', open: true});
                } else {
                    setDialogProps({title: 'No eres tú, soy yo... ☹️', content: 'Hubo un problema intentando crear tu cuenta, por favor contáctanos para darte una solución.', leftButton: 'Ok', rightButton: '', open: true});
                }
            });
        } else {
            setDialogProps({title: 'Oops! 🤔', content: 'Por favor revisa que haz ingresado información en todos los campos, parece que alguno está faltante.', leftButton: 'Ok', rightButton: '', open: true});
        }
    };

    let handlePreviousExperience = () => {
        setPreviousExperience((!previousExperience));
    }

return (
<ThemeProvider theme={theme}>
    <Grow in={(props.view == 'signup')}>
        <Container component="main" maxWidth="md">
                <CssBaseline />
                <Box
                    sx={{
                        marginTop: 3,
                        display: 'flex',
                        flexDirection: 'column',
                        alignItems: 'center',
                    }}
                >
                    <DialogSports dialogProps={dialogProps} setDialogProps={setDialogProps} handleDialogLeftButton={handleDialogLeftButton}/>
                    <Grid alignSelf={'flex-start'}>
                        <Button onClick={() => props.setView('login')}>
                            <CIcon 
                                icon={icon.cilArrowLeft} 
                                className='text-primary' 
                                style={{height: '20px', marginRight: '20px'}}
                            />
                            <Typography sx={{textTransform: 'none'}}>Iniciar sesión</Typography>
                        </Button>
                    </Grid>
                    <Box m={4}>
                        <Logo />
                    </Box>
                    <Typography component="h1" variant="h5" sx={{fontFamily: 'LarkenMedium'}}>
                        Registro
                    </Typography>
                    <Box component="form" onSubmit={handleSubmit} noValidate sx={{ mt: 1 }}>
                    <Typography>¿Cómo te llamas? 😀</Typography>
                        <TextField
                        margin="normal"
                        required
                        id="name"
                        label="Nombre"
                        fullWidth
                        name="name"
                        autoComplete="text"
                        value={name}
                        onChange={(e) => setName(e.target.value)}
                        />
                        <TextField
                        margin="normal"
                        required
                        id="lastname"
                        label="Apellido"
                        fullWidth
                        name="lastname"
                        autoComplete="text"
                        value={lastName}
                        onChange={(e) => setLastName(e.target.value)}
                        />
                        <Divider sx={{margin: 2}}/>
                        <Typography>Para iniciar sesión 📝</Typography>
                        <TextField
                        margin="normal"
                        required
                        fullWidth
                        id="email"
                        label="Correo electrónico"
                        name="email"
                        autoComplete="email"
                        value={email}
                        onChange={(e) => setEmail(e.target.value)}
                        />
                        <TextField
                        margin="normal"
                        required
                        fullWidth
                        id="password"
                        label="Contraseña"
                        name="password"
                        type='password'
                        value={password}
                        onChange={(e) => setPassword(e.target.value)}
                        />
                        <TextField
                        margin="normal"
                        required
                        fullWidth
                        name="phonenumber"
                        label="# Celular"
                        type="tel"
                        id="phonenumber"
                        value={phoneNumber}
                        onChange={(e) => setPhoneNumber(e.target.value)}
                        />
                        <LocalizationProvider dateAdapter={AdapterDateFns}>
                            <DatePicker 
                                label='Fecha de nacimiento'
                                id='birthday'
                                required
                                format="dd/MM/yyyy"
                                views={['year', 'month', 'day']}
                                openTo='year'
                                sx={{width: '100%', marginTop: '15px'}}
                                onChange={(newValue) => { setBirthday(newValue) }}
                            />
                        </LocalizationProvider>
                        <Grid container direction='row' sx={{margin: '20px 0px'}}>
                            <Grid item xs={10}textAlign={'initial'}>
                                <Typography>Tengo experiencia previa en pilates ✨</Typography>
                            </Grid>
                            <Grid item xs={2} textAlign={'right'} alignContent={'end'}>
                            <FormControlLabel
                                control={
                                    <Switch 
                                        required
                                        id='previousExperience'
                                        sx={{margin: 0}} 
                                        value='previousExperience' 
                                        checked={previousExperience} 
                                        color="primary" 
                                        onChange={() => handlePreviousExperience()}
                                    />
                                }
                            />
                            </Grid>
                        </Grid>
                        <Divider sx={{margin: 2}}/>
                        <Typography>¿Quieres agregar una foto de perfil? 📸</Typography>
                        <Typography>Si prefieres puedes subirla después</Typography>
                        <Grid container flex={1}>
                            <ProfilePicture 
                                userData={{pictureURL: pictureURL, _id: ''}}
                                postAction={(base64Image) => setPictureURL(base64Image)}
                            />
                        </Grid>
                        <Typography variant='caption' sx={{margin: '10px 0px'}}>
                            Al hacer clic en Continuar, aceptas los 
                        </Typography>
                        <Typography variant='caption' sx={{margin: '10px 0px'}}>
                            <a href={window.location + 'termsandconditions'}> Términos y Condiciones </a>,
                        </Typography>
                        <Typography variant='caption' sx={{margin: '10px 0px'}}>
                            de Sculpt Studio MX
                        </Typography>
                        <Button
                        type="submit"
                        fullWidth
                        variant="contained"
                        sx={{ 
                            mt: 3, 
                            mb: 5, 
                            color: '#FFF', 
                            textTransform: 'none',
                            p: 0.75,
                            boxShadow: '0px 4px 20px rgba(0, 0, 0, 0.25)',
                        }}
                        >
                            Continuar
                        </Button>
                    </Box>
                </Box>
        </Container>
    </Grow>
</ThemeProvider>
);
}