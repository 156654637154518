import React, { useEffect, useState } from "react";
import CIcon from "@coreui/icons-react";
import * as icon from '@coreui/icons';
import { 
    Grid, 
    Button,
    IconButton,
    Typography, 
    Box, 
    TextField, 
    Paper,
    Table,
    TableBody,
    TableCell,
    TableContainer,
    TableHead,
    TableRow,
    TableFooter,
    TablePagination,
    TableSortLabel,
    CircularProgress
} from "@mui/material";
import consts from '../../consts/consts';
import axios from "axios";
import ActionsModal from "./ActionsModal";
import EditIcon from '@mui/icons-material/Edit';
import DeleteIcon from '@mui/icons-material/Delete';
import AssignmentIcon from '@mui/icons-material/Assignment';
import PeopleIcon from '@mui/icons-material/People';
import { ShoppingCart } from "@mui/icons-material";
import DialogSports from "../Dialog/Dialog";
import PeopleModal from "./PeopleModal";
import UserClassesModal from "./UserClassesModal";
import MembershipModal from "./MembershipModal";


export default function AdminTable(props) {
    // Table Data
    const [data, setData] = useState([]);
    // Schema
    const [schema, setSchema] = useState([]);
    const [modalOpen, setModalOpen] = useState(false);
    const [searchText, setSearchText] = useState('');
    const [selectedItem, setSelectedItem] = useState(null);
    const [modalAction, setModalAction] = useState('Add');
    const [dialogProps, setDialogProps] = useState({ title: '', content: '', leftButton: '', rightButton: '', open: false });
    const [deleteID, setDeleteID] = useState('');
    const [order, setOrder] = useState('');
    const [orderBy, setOrderBy] = useState('');
    const [isLoading, setIsLoading] = useState(true);
    // Set edit class ID
    const [classEdit, setClassEdit] = useState(null);
    // Page
    const [page, setPage] = useState(0);
    const [rowsPerPage, setRowsPerPage] = useState(10);
    // Loading
    const [loading, setLoading] = useState(true);
    // Set view classes
    const [userID, setUserID] = useState(null);
    // Set view membership
    const [membershipID, setMembershipID] = useState(null);
    // Users
    const [users, setUsers] = useState([]);

    // Handle change page
    const handleChangePage = (event, newPage) => {
        setPage(newPage);
    };

    // Handle change rows per page
    const handleChangeRowsPerPage = (event) => {
        setRowsPerPage(parseInt(event.target.value, 10));
        setPage(0);
    };

    // Hear for changes in schema, if schema is sales or classes, we sort data by date descending
    useEffect(() => {
        if (props.adminView != null || props.adminView != '') {
            if (props.adminView == 'sale' || props.adminView == 'schedule') {
                setOrder('desc');
                setOrderBy('date');
            }
        }
    }, [props.adminView]);

    // First use effect, load view schema
    useEffect(() => {
        getSchema();
        setUsers(JSON.parse(localStorage.getItem('users')));
    }, []);

    // When modalOpens or the dialog gets updated, we pull new data from DB
    useEffect(() => {
        getData();
    }, [modalOpen, dialogProps.open, props.adminView]);

    // Get all data from DB
    const getData = async () => {
        await axios.post(`${process.env.REACT_APP_ENV == 'dev' ? process.env.REACT_APP_BLL_URL : ''}/${props.adminView}/getAll`)
            .then((res) => {
                setLoading(false);
                setData(res.data.result);
                setIsLoading(false);
            })
            .catch((err) => {
                console.log(err);
            });
    }

    // Get view schema from DB
    const getSchema = async () => {
        await axios.post(`${process.env.REACT_APP_ENV == 'dev' ? process.env.REACT_APP_BLL_URL : ''}/${props.adminView}/getSchema`)
        .then((res) => {
            setSchema(res.data);
        })
        .catch((err) => {
            console.log(err);
        });
    }

    // Get singular display name
    let getDisplayName = () => {
        return (consts.adminCategories.find((e) => e.id == props.adminView)?.singular);
    }

    // Get key readable value from schema
    const getReadableValue = (keyName) => {
        const readableValue = schema[keyName]?.readableValue;
        return (readableValue == undefined || readableValue == null) ? keyName : readableValue;
    }

    // Add new entry
    const addItem = () => {
        setSelectedItem(null);
        setModalAction('Add');
        setModalOpen(true);
    }

    // Edit button for entry
    const handleEdit = (id) => {
        const item = data.find(d => d._id === id);
        setSelectedItem(item);
        setModalAction('Edit');
        setModalOpen(true);
    }

    // Delete button for entry
    const handleDelete = (item) => {
        setDialogProps(
            {
                title: 'Alerta ⚠️', 
                content: `¿Estas segur@ de eliminar este ${getDisplayName()}?`,
                leftButton: 'No',
                rightButton: 'Si',
                open: true
            }
        )
        setDeleteID(item._id);
    }

    // Dialog Left Button 
    const handleDialogLeftButton = () => {
        setDialogProps({ title: '', content: '', leftButton: '', rightButton: '', open: false });
        setDeleteID('');
    }

    // Dialog Right button
    const handleDialogRightButton = async () => {
        setDialogProps({ title: '', content: '', leftButton: '', rightButton: '', open: false });
        if (deleteID != '') {
            // Delete Confirmation
            await axios.post(`${process.env.REACT_APP_ENV == 'dev' ? process.env.REACT_APP_BLL_URL : ''}/${props.adminView}/delete`, {"ID" : deleteID})
                .then((res) => {
                    if (res.status == 200 && res.data.success == true) {
                        setDialogProps({ title: 'Información ❗️', content: `${getDisplayName()} eliminado con éxito`, leftButton: 'Ok', rightButton: '', open: true });
                    } else {
                        setDialogProps({ title: 'Información ❗️', content: `Hubo un problema eliminando ${getDisplayName()}, por favor contacta a soporte.`, leftButton: 'Ok', rightButton: '', open: true });
                    }
                })
                .catch((err) => {
                    setDialogProps({ title: 'Información ❗️', content: `Hubo un problema eliminando ${getDisplayName()}, por favor contacta a soporte.`, leftButton: 'Ok', rightButton: '', open: true });
                });
        } else {
            // Other action
            
        }
        
    }

    let excludedKeys = [
        "_id", 
        "cart", 
        "reservations", 
        "password", 
        "createdAt", 
        "updatedAt", 
        "__v", 
        "type", 
        "assignedClasses", 
        "cart",
        "participantIDS",
        "id",
        "teacherName",
        "otp",
        "creditsPilates",
        "creditsSculpt",
        "packs",
        "phoneVerified",
        "discipline"
    ];

    // Format UTC to CST date return format dd/mm/yyyy
    let formatUTCDate = (date) => {
        const dateObj = new Date(date);
        const day = dateObj.getDate().toString().padStart(2, '0');
        const month = (dateObj.getMonth() + 1).toString().padStart(2, '0');
        const year = dateObj.getFullYear();
        return `${day}/${month}/${year}`;
    }

    // Format UTC to CST time return format hh:mm AM/PM
    let formatUTCTime = (date) => {
        const dateObj = new Date(date);
        let hours = dateObj.getHours();
        let minutes = dateObj.getMinutes();
        let period = hours >= 12 ? 'PM' : 'AM';
        hours = hours % 12 || 12;
        return `${hours}:${minutes.toString().padStart(2, '0')} ${period}`;
    }

    // Post process value if required
    let postProcessValue = (key, value) => {
        if (value === null || value === undefined) {
            return '';
        } else if (key == 'teacherID'){
            let foundCoach = props.coaches.find((coach) =>  coach._id == value);
            return `${foundCoach.firstName} ${foundCoach.lastName}`;
        } else if (key == 'status') {
            switch(value) {
                case 'scheduled': return 'Activa';
                case 'cancelled': return 'Cancelada';
                case 'finalized': return 'Finalizada';
                default: return '';
            }
        } else if (key === 'birthday' || key === 'purchaseDate' || key === 'startDate' || key === 'endDate' || key === 'date' || key === 'createdAt' || key === 'updatedAt') {
            return formatUTCDate(value);
        } else if (key === 'time' || key === 'endTime' || key === 'purchaseTime') {
            return formatUTCTime(value);
        } else if (key === 'previousExperience' || key === 'phoneVerified') {
            return value ? 'Sí' : 'No';
        } else if (key === 'discipline') {
            return (value === 'pilates_reformer' ? 'Pilates Reformer' : value === 'sculpt_tone' ? 'Sculpt / Tone' : 'Todas');
        } else if (key === 'pictureURL') {
            if (typeof value === 'string') {
                return <img src={value} style={{width: '40px', height: '40px', borderRadius: '50%', objectFit: 'cover'}} />;
            } else {
                return  <Box
                            sx={{
                                bgcolor: 'primary.main',
                                color: 'white',
                                width: 40,
                                height: 40,
                                borderRadius: '50%',
                                display: 'flex',
                                alignItems: 'center',
                                justifyContent: 'center'
                            }}
                        >
                            <Typography>{value.firstLetter + value.lastLetter}</Typography>
                        </Box>;
            }
        } else if (key === 'purchaserID') {
            // Find value in users array and obtain firstName and lastName
            const user = users.find((user) => user._id === value);
            return user ? `${user.firstName} ${user.lastName}` : '';
        } else if (key === 'classTitle') {
            switch(value) {
                case 'pilates_mat': return 'PILATES MAT';
                case 'barre': return 'BARRE';
                case 'sculpt': return 'SCULPT';
                default: return value;
            }
        } else if (key === 'classDescription') {
            switch(value) {
                case 'upper_body': return 'UPPER BODY';
                case 'lower_body': return 'LOWER BODY';
                case 'full_body': return 'FULL BODY';
                default: return value;
            }
        }else {
            return value;
        }
    }

    // Function to download data in excel

    const downloadInExcel = async () => {
        const csvData = convertToCSV(data);
        const blob = new Blob([csvData], { type: 'text/csv' });
        const url = URL.createObjectURL(blob);
        const link = document.createElement('a');
        link.href = url;
        link.setAttribute('download', 'data.csv');
        document.body.appendChild(link);
        link.click();
        document.body.removeChild(link);
    };

    const convertToCSV = (data) => {
        const header = Object.keys(data[0]).map(keyName => getKeyReadableValue(keyName) || keyName).join(',');
        const rows = data.map(item => Object.values(item).join(','));
        return [header, ...rows].join('\n');
    };

    const getKeyReadableValue = (keyName) => {
        const readableValue = schema[keyName]?.readableValue;
        return (readableValue == undefined || readableValue == null) ? null : readableValue;
    };

    // Function to handle class participants
    const handleClassParticipants = (item) => {
        setClassEdit(item);
    }

    // Function to handle view classes of user
    const handleViewClasses = (item) => {
        setUserID(item._id);
    }

    // Function to handle view membership of user
    const handleViewMembership = (item) => {
        setMembershipID(item._id);
    }

    // Function to sort data
    const handleRequestSort = (event, property) => {
        const isAsc = orderBy === property && order === 'asc';
        setOrder(isAsc ? 'desc' : 'asc');
        setOrderBy(property);
    };
    
    const stableSort = (array, comparator) => {
        const stabilizedThis = array.map((el, index) => [el, index]);
        stabilizedThis.sort((a, b) => {
            const order = comparator(a[0], b[0]);
            if (order !== 0) return order;
            return a[1] - b[1];
        });
        return stabilizedThis.map((el) => el[0]);
    };
    
    const getComparator = (order, orderBy) => {
        return order === 'desc'
            ? (a, b) => descendingComparator(a, b, orderBy)
            : (a, b) => -descendingComparator(a, b, orderBy);
    };
    
    const descendingComparator = (a, b, orderBy) => {
        if (b[orderBy] < a[orderBy]) {
            return -1;
        }
        if (b[orderBy] > a[orderBy]) {
            return 1;
        }
        return 0;
    };

    // Process value for search
    const processValueForSearch = (columnId, value) => {
        value = String(value);
        switch (columnId) {
            case 'birthday':
            case 'date':
                return (value != undefined || value != null) ? new Date(value).toLocaleDateString('en-GB') : ''; // Format day to DD/MM/YYYY
            case 'time':
            case 'endTime':
                return (value != undefined || value != null) ? formatUTCTime(value) : '';
            case 'purchaserID' : {
                return (value != undefined || value != null) ? getNameFromUserID(value) : '';
            }
            default:
                return value.toString().toLowerCase();
        }
    };

    // Get name from user ID
    const getNameFromUserID = (value) => {
        const user = users.find((user) => user._id === value);
        let name = user ? `${user.firstName ? user.firstName : ''} ${user.lastName ? user.lastName : ''}` : '';
        return name;
    }

    // Filter data based on search text using processed values
    let filteredData = data.filter((item) => {
        return Object.keys(schema).some((column) => {
            if (!excludedKeys.includes(column) && column != 'pictureURL') {
                const processedValue = processValueForSearch(column, item[column]);
                return String(processedValue).toLowerCase().includes(searchText.toLowerCase());
            }
        });
    });

    // Sort filtered data based on order and orderBy
    filteredData = stableSort(filteredData, getComparator(order, orderBy));

    return (
        <>
            <ActionsModal 
                modalOpen={modalOpen} 
                setModalOpen={setModalOpen} 
                getDisplayName={getDisplayName} 
                adminView={props.adminView} 
                action={modalAction} 
                selectedItem={selectedItem}
                schema={schema}
                coaches={props.coaches}
            />
            <PeopleModal 
                classEdit={classEdit} 
                setClassEdit={setClassEdit}
                getData={getData}
            />
            <UserClassesModal
                userID={userID}
                setUserID={setUserID}
                coaches={props.coaches}
                logguedInUser={props.logguedInUser}
            />
            <MembershipModal
                membershipID={membershipID}
                setMembershipID={setMembershipID}
            />
            <DialogSports dialogProps={dialogProps} handleDialogLeftButton={handleDialogLeftButton} handleDialogRightButton={handleDialogRightButton}/>
            <Grid container direction={'column'} padding={{xs: 0, sm: 1, md: 2}}>
                <Grid item xs={2}>
                    <Grid container direction={'row'} spacing={{xs: 1, sm: 2, md: 3}}>
                        <Grid item xs={12} sm={6} md={3}>
                            <Button 
                                variant="contained" 
                                sx={{color: '#FFF', textTransform: 'none', width: '100%'}}
                                onClick={() => addItem()}
                                >
                                <CIcon icon={icon.cilPlus} style={{width: '25px', margin: '0px 10px'}} />
                                <Typography>{`Agregar ${getDisplayName()}`}</Typography>
                            </Button>
                        </Grid>
                        <Grid item xs={12} sm={6} md={3}>
                            <Button 
                                variant="contained" 
                                sx={{color: '#FFF', textTransform: 'none', width: '100%'}}
                                onClick={() => downloadInExcel()}
                                >
                                <CIcon icon={icon.cilCloudDownload} style={{width: '25px', margin: '0px 10px'}} />
                                <Typography>{`Descargar en Excel`}</Typography>
                            </Button>
                        </Grid>
                        <Grid item xs={12} sm={12} md={6} alignContent={'flex-end'} alignSelf={'flex-end'} alignItems={'flex-end'} textAlign={'end'}>
                            <Box sx={{ display: 'flex', alignItems: 'flex-end'}}>
                                <CIcon icon={icon.cilMagnifyingGlass} style={{width: '25px', margin: '10px 10px', color: 'action.active'}}></CIcon>
                                <TextField 
                                    id="input-with-sx" 
                                    label="Buscar ..." 
                                    variant="standard" 
                                    fullWidth
                                    value={searchText}
                                    onChange={(e) => setSearchText(e.target.value)}
                                />
                            </Box>
                        </Grid>
                    </Grid>
                </Grid>
                <Grid item xs={10} style={{ maxWidth: '93vw', marginTop: '20px' }}>
                {(filteredData.length !== 0 && schema != null && loading != true) ? (
                        <Paper sx={{ width: '100%', overflow: 'hidden' }}>
                            <TableContainer>
                                <Table aria-label="data-table" stickyHeader>
                                    <TableHead>
                                        <TableRow key={'th-0'}>
                                            {
                                                Object.keys(schema).map((keyName) => {
                                                    if (!(excludedKeys.includes(keyName))) {
                                                        return (
                                                            <TableCell key={`table-head-cell-${keyName}`} sortDirection={orderBy === keyName ? order : false}>
                                                                <TableSortLabel
                                                                    direction={orderBy === keyName ? order : 'asc'}
                                                                    onClick={(event) => handleRequestSort(event, keyName)}
                                                                >
                                                                    {getReadableValue(keyName)}
                                                                </TableSortLabel>
                                                            </TableCell>
                                                        )
                                                    }
                                                })
                                            }
                                            <TableCell>Acciones</TableCell> {/* New column for actions */}
                                        </TableRow>
                                    </TableHead>
                                    <TableBody>
                                        {
                                            (filteredData.length > 0 
                                                ? filteredData.slice(page * rowsPerPage, page * rowsPerPage + rowsPerPage)
                                                : filteredData
                                            ).map((obj, index) => (
                                                <TableRow key={`tr-${index}`}>
                                                    {schema && Object.keys(schema).map((column) => {
                                                        if (!(excludedKeys.includes(column))) {
                                                            const value = obj[column];
                                                            if (column === 'pictureURL' && value === '') {
                                                                let firstLetter = obj.firstName.charAt(0);
                                                                let lastLetter = obj.lastName.charAt(0);
                                                                return <TableCell key={column.id}>{postProcessValue(column, {firstLetter, lastLetter})}</TableCell>
                                                            } else {
                                                                return <TableCell key={column.id}>{value !== undefined ? postProcessValue(column, value) : ''}</TableCell>
                                                            }
                                                        }
                                                        
                                                    })}
                                                    <TableCell>
                                                        <Box sx={{ display: 'flex', gap: 1 }}>
                                                            {/* Edit button */}
                                                            <IconButton
                                                                sx={{ 
                                                                    backgroundColor: 'primary.main', 
                                                                    '&:hover': { backgroundColor: 'primary.dark' }, 
                                                                    color: 'white', 
                                                                    transform: 'scale(0.75)'  // Reduce size by 25%
                                                                }}
                                                                aria-label="edit-btn"
                                                                onClick={() => handleEdit(obj._id)}
                                                                disabled={(props.adminView == 'sales')}
                                                            >
                                                                <EditIcon sx={{ color: 'white' }} />
                                                            </IconButton>
                                                            {/* Delete button */}
                                                            <IconButton
                                                                sx={{ 
                                                                    backgroundColor: 'primary.main', 
                                                                    '&:hover': { backgroundColor: 'primary.dark' }, 
                                                                    color: 'white', 
                                                                    transform: 'scale(0.75)'  // Reduce size by 25%
                                                                }}
                                                                aria-label="delete-btn"
                                                                onClick={() => handleDelete(obj)}
                                                                disabled={(props.adminView == 'sales')}
                                                            >
                                                                <DeleteIcon sx={{ color: 'white' }} />
                                                            </IconButton>
                                                            {/* Participants button */}
                                                            {props.adminView === 'schedule'
                                                                &&
                                                                <IconButton
                                                                    sx={{ 
                                                                        backgroundColor: 'primary.main', 
                                                                        '&:hover': { backgroundColor: 'primary.dark' }, 
                                                                        color: 'white', 
                                                                        transform: 'scale(0.75)'  // Reduce size by 25%
                                                                    }}
                                                                    aria-label="participants-btn"
                                                                    onClick={() => handleClassParticipants(obj)}
                                                                >
                                                                    <PeopleIcon sx={{ color: 'white' }} />
                                                                    <div style={{
                                                                        position: 'absolute', 
                                                                        top: '-10px',
                                                                        right: '-10px',
                                                                        color: '#FFF',
                                                                        backgroundColor: 'red',
                                                                        padding: '2px 7px',
                                                                        borderRadius: '50%',
                                                                        fontSize: '18px'
                                                                    }}>
                                                                        {obj.participantIDS.length}
                                                                    </div>
                                                                </IconButton>
                                                            }
                                                            {/* Classes of user button */}
                                                            {props.adminView === 'user'
                                                                &&
                                                                <IconButton
                                                                    sx={{ 
                                                                        backgroundColor: 'primary.main', 
                                                                        '&:hover': { backgroundColor: 'primary.dark' }, 
                                                                        color: 'white', 
                                                                        transform: 'scale(0.75)'  // Reduce size by 25%
                                                                    }}
                                                                    aria-label="participants-btn"
                                                                    onClick={() => handleViewClasses(obj)}
                                                                >
                                                                    <AssignmentIcon sx={{ color: 'white' }} />
                                                                </IconButton>
                                                            }
                                                            {/* Classes of user button */}
                                                            {props.adminView === 'user'
                                                                &&
                                                                <IconButton
                                                                    sx={{ 
                                                                        backgroundColor: 'primary.main', 
                                                                        '&:hover': { backgroundColor: 'primary.dark' }, 
                                                                        color: 'white', 
                                                                        transform: 'scale(0.75)'  // Reduce size by 25%
                                                                    }}
                                                                    aria-label="participants-btn"
                                                                    onClick={() => handleViewMembership(obj)}
                                                                >
                                                                    <ShoppingCart sx={{ color: 'white' }} />
                                                                </IconButton>
                                                            }
                                                        </Box>
                                                    </TableCell>
                                                </TableRow>
                                            ))
                                        }
                                    </TableBody>
                                    <TableFooter>
                                        <TableRow>
                                            <TableCell colSpan={Object.keys(data[0]).length}>
                                                <TablePagination
                                                    component='div'
                                                    count={data.length}
                                                    page={page}
                                                    onPageChange={handleChangePage}
                                                    rowsPerPage={rowsPerPage}
                                                    onRowsPerPageChange={handleChangeRowsPerPage}
                                                    rowsPerPageOptions={[5, 10, 25]}
                                                />
                                            </TableCell>
                                        </TableRow>
                                    </TableFooter>
                                </Table>
                            </TableContainer>
                        </Paper>
                    ) : (
                        loading 
                        ? <CircularProgress size={150} sx={{m: {xs: 4, sm: 6, md: 8, lg: 10}}}/>
                        : <Typography variant="body1" align="center">Parece que no existen registros para tu búsqueda, intenta con otro valor.</Typography>
                    )}
                </Grid>
            </Grid>
        </>
    )
}