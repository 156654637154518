import React, { useState, useEffect } from "react";
import axios from "axios";
import { Grid, Typography, Avatar, Button, useTheme, Box, Divider, IconButton, Popover } from "@mui/material";
import DialogSports from '../Dialog/Dialog';
import { getFormattedTime, convertISODateToLocalDate, getDayName, getDayNumber, getMonth, getStatus } from "../../helpers/functions";
import CIcon from "@coreui/icons-react";
import * as icon from '@coreui/icons';

export default function MonthClassCard(props) {
    // States
    const [coachName, setCoachName] = useState({ name: '', lastName: '' });
    const [coachPicture, setCoachPicture] = useState('');
    const [dialogProps, setDialogProps] = useState({ title: '', content: '', leftButton: '', rightButton: '', open: false });
    const [refundable, setRefundable] = useState(false);
    const [anchorEl, setAnchorEl] = useState(null);
    const [inDeletion, setInDeletion] = useState(false);

    // Theme
    const theme = useTheme();

    // Get coach name and picture
    useEffect(() => {
        let coach = props.coaches.find(coach => coach._id == props.classData.teacherID);
        (coach) && setCoachName({ name: coach.firstName, lastName: coach.lastName });
        (coach) && setCoachPicture(coach.pictureURL);
    }, []);

    // Calculate minutes duration of a class -- both variables are in ISO format
    const calculateDuration = (startTime, endTime) => {
        const start = new Date(startTime);
        const end = new Date(endTime);
        const diff = Math.abs(end - start);
        const minutes = Math.ceil((diff / 1000) / 60);
        return `${minutes} min`;
    };

    // Handle popover open
    const handlePopoverOpen = (event) => {
        setAnchorEl(event.currentTarget);
    };

    // Handle popover close
    const handlePopoverClose = () => {
        setAnchorEl(null);
    };

    const open = Boolean(anchorEl);

    // Handle cancel class
    let cancelClass = () => {
        // Minutes difference limit
        let maxMinutes = 120;

        setInDeletion(true);
        // Obtain date and time of class and current date and time
        let classDay = new Date(props.classData.date);
        let classDayTime = new Date(classDay.getFullYear(), classDay.getMonth(), classDay.getDate(), new Date(props.classData.time).getHours(), new Date(props.classData.time).getMinutes());
        let currentDayTime = new Date();

        // Define difference in milliseconds
        let differenceMilliseconds = Math.abs(currentDayTime - classDayTime);
        let differenceMinutes = Math.floor(differenceMilliseconds / (1000 * 60));

        // Evaluate if class is refundable
        (differenceMinutes > maxMinutes) ? setRefundable(true) : setRefundable(false);

        // Set dialog
        setDialogProps({
            title: 'Cancelar clase',
            content: (differenceMinutes > maxMinutes) ?
                '¿Estás segur@ que deseas cancelar esta clase? Se te reembolsará tu crédito. 😊' :
                'Lo sentimos, no puedes cancelar esta clase. Solo puedes cancelar clases con 2 horas de anticipación para recibir tu crédito de vuelta, si no puedes asistir no te preocupes, simplemente falta a la case, estamos trabajando para brindarte una mejor experiencia.',
            leftButton: (differenceMinutes > maxMinutes) ? 'No' : 'Ok',
            rightButton: (differenceMinutes > maxMinutes) ? 'Si' : '',
            open: true
        });
    }

    // Handle Left button of dialog
    const handleDialogRightButton = () => {
        setDialogProps(
            {
                title: '',
                content: '',
                leftButton: '',
                rightButton: '',
                open: false
            }
        );
        // Remove class from user
        axios.post(`${process.env.REACT_APP_ENV == 'dev' ? process.env.REACT_APP_BLL_URL : ''}/schedule/removeClassParticipants`,
            { "removeParticipant": props.logguedInUser._id, "classID": props.classData._id })
            .then(async (res) => {
                // If refundable, refund user
                if (refundable) {
                    // [1] - Obtain the user's closest expiraitonDate pack in packs array
                        let user = props.logguedInUser;
                        // sort them by expirationDate
                        let packs = user.packs.sort((a, b) => new Date(a.expirationDate) - new Date(b.expirationDate));
                        // Get the most recent expirationDate pack
                        let mostRecentPack = packs[0];

                    // [2] - Add 1 class to the remainingClasses pack
                        mostRecentPack.remainingClasses += 1;
                        // Update mostRecentPack in original packs array from user object
                        let newPacks = user.packs.map(pack => pack.id == mostRecentPack.id ? mostRecentPack : pack);

                    // [3] - Update user in DB
                    await axios.post(`${process.env.REACT_APP_ENV == 'dev' ? process.env.REACT_APP_BLL_URL : ''}/user/update`, { _id: user._id, packs: newPacks })
                        .then((res) => {
                            if (res.data.success) {
                                props.handleTabChange();
                            }
                        })
                        .catch((err) => {
                            console.log(err);
                        });
                } else {
                    props.handleTabChange();
                }
            })
            .catch((err) => {
                console.log(err);
            });
    }

    // Handle Left button of dialog
    const handleDialogLeftButton = () => {
        setDialogProps(
            {
                title: '',
                content: '',
                leftButton: '',
                rightButton: '',
                open: false
            }
        );
        setInDeletion(false);
    }

    // Get class title
    const getClassTitleOrDesc = (string) => {
        switch (string) {
            case 'pilates_mat': return 'PILATES MAT'
            case 'barre': return 'BARRE'
            case 'sculpt': return 'SCULPT'
            case 'upper_body': return 'UPPER BODY'
            case 'lower_body': return 'LOWER BODY'
            case 'full_body': return 'FULL BODY'
            default: return string;
        }
    }

    return (
        <>
            <DialogSports dialogProps={dialogProps} setDialogProps={setDialogProps} handleDialogRightButton={handleDialogRightButton} handleDialogLeftButton={handleDialogLeftButton} />
            {/* Card popover */}
            <Popover
                id="card-popover"
                open={open}
                anchorEl={anchorEl}
                onClose={handlePopoverClose}
                anchorOrigin={{
                    vertical: 'top',
                    horizontal: 'right',
                }}
                transformOrigin={{
                    vertical: 'bottom',
                    horizontal: 'left',
                }}
            >
                <Button
                    sx={{
                        textTransform: 'none',
                    }}
                    onClick={() => cancelClass()}
                    disabled={inDeletion}
                >
                    {'Cancelar clase'}
                </Button>
            </Popover>

            {/* Card content */}
            <Grid
                container
                direction='column'
                sx={{
                    mt: '10px',
                    marginLeft: '5%',
                    display: 'inline-block',
                    width: '90%',
                    // height: '170px',
                    transition: 'all 0.35s',
                    overflow: 'hidden',
                    zIndex: 1,
                    boxShadow: '0px 0px 10px 0px rgba(0,0,0,0.5)',
                }}
            >
                {/* Class title */}
                <Grid
                    item
                    xs={4}
                    sx={{
                        p: 0.5,
                        width: '100%',
                        bgcolor: theme.palette.primary.main
                    }}
                >
                    <Grid container direction={'row'}>
                        {/* Class title */}
                        <Grid item xs={7} alignContent={'center'}>
                            <Typography sx={{ color: theme.palette.grey[100], ml: '5px' }}>
                                {getClassTitleOrDesc(props.classData.classTitle)}
                            </Typography>
                        </Grid>
                        {/* Actions button */}
                        <Grid item xs={3} sx={{ bgcolor: 'rgba(0,0,0,0.5)' }} alignContent={'center'}>
                            <Typography
                                sx={{
                                    fontSize: 15,
                                    color: '#fff',
                                    textAlign: 'center',
                                }}>
                                {getStatus(props.classData.status)}
                            </Typography>
                        </Grid>
                        <Grid item xs={2} textAlign={'right'}>
                            <IconButton
                                onClick={(e) => handlePopoverOpen(e)}
                            >
                                <CIcon
                                    icon={icon.cilOptions}
                                    className='text-primary'
                                    style={{ height: '20px', color: '#FFF', rotate: '90deg' }}
                                />
                            </IconButton>
                        </Grid>
                    </Grid>

                </Grid>
                <Grid item xs={4} textAlign={'center'}>
                    {/* Profile picture of coach */}
                    <Grid container direction='row'>
                        <Grid item xs={5} p={1}>
                            <Typography
                                sx={{
                                    fontSize: 20,
                                    fontFamily: 'LarkenBold',
                                    fontWeight: 'bold',
                                    color: theme.palette.primary.main,
                                    textAlign: 'left'
                                }}
                            >
                                {getDayName(new Date(convertISODateToLocalDate(props.classData.date)).getDay())}
                            </Typography>
                            <Typography
                                sx={{
                                    fontSize: 40,
                                    fontFamily: 'LarkenBold',
                                    fontWeight: 'bold',
                                    color: theme.palette.primary.main,
                                    textAlign: 'left'
                                }}
                            >
                                {getDayNumber(props.classData.date)}
                            </Typography>
                            <Typography
                                sx={{
                                    fontSize: 20,
                                    fontFamily: 'LarkenBold',
                                    fontWeight: 'bold',
                                    color: theme.palette.primary.main,
                                    textAlign: 'left'
                                }}
                            >
                                {getMonth(new Date(props.classData.date).getMonth())}
                            </Typography>
                        </Grid>
                        {/* Divider */}
                        <Grid item xs={1}>
                            <Divider orientation="vertical" sx={{ height: '90%', mt: '50%' }} />
                        </Grid>
                        {/* Coach profile picture */}
                        <Grid item xs={6} textAlign={'right'}>
                            <Box m={1}>
                                {coachPicture ?
                                    <img src={coachPicture} alt='coach' style={{ width: 70, height: 70, objectFit: 'cover', borderRadius: '50%' }} /> :
                                    <Avatar
                                        sx={{
                                            bgcolor: '#000',
                                            opacity: 0.40,
                                            display: 'inline-flex',
                                            height: 70,
                                            width: 70,
                                            fontSize: 45,
                                            fontFamily: 'LarkenBold',
                                            margin: 1,
                                        }}>
                                        {String(coachName.name.charAt(0)).toLocaleUpperCase() + String(coachName.lastName.charAt(0)).toLocaleUpperCase()}
                                    </Avatar>
                                }
                                <Typography
                                    sx={{
                                        fontSize: 20,
                                        fontFamily: 'LarkenBold',
                                        fontWeight: 'bold',
                                        color: theme.palette.primary.main,
                                        lineBreak: ''
                                    }}
                                >
                                    {`${coachName.name} ${coachName.lastName}`}
                                </Typography>
                            </Box>
                        </Grid>
                    </Grid>


                    <Divider orientation="horizontal" sx={{ width: '90%', m: 0.5 }} />
                    {/* Class description */}
                    <Typography sx={{ fontSize: 14, m: 1 }}>
                        {getClassTitleOrDesc(props.classData.classDescription)}
                    </Typography>
                </Grid>
                {/* Time display */}
                <Grid item xs={4} sx={{ m: 1 }}>
                    <Grid container direction='row'>
                        <Grid item xs textAlign={'initial'}>
                            <Typography sx={{ fontSize: 20 }}>
                                {getFormattedTime(props.classData.time)}
                            </Typography>
                            <Typography sx={{ fontSize: 15 }}>
                                {'Inicio'}
                            </Typography>
                        </Grid>
                        <Grid item xs textAlign={'center'} alignContent={'center'}>
                            <Typography
                                sx={{
                                    fontSize: 20,
                                    borderRadius: '50px',
                                    padding: 0.5,
                                    backgroundColor: theme.palette.primary.main,
                                    color: theme.palette.grey[100]
                                }}>
                                {calculateDuration(props.classData.time, props.classData.endTime)}
                            </Typography>
                        </Grid>
                        <Grid item xs textAlign={'end'}>
                            <Typography sx={{ fontSize: 20 }}>
                                {getFormattedTime(props.classData.endTime)}
                            </Typography>
                            <Typography sx={{ fontSize: 15 }}>
                                {'Fin'}
                            </Typography>
                        </Grid>
                    </Grid>
                </Grid>
            </Grid>
        </>
    );
}