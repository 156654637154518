import React, { useEffect, useState } from "react";
import {
    PaymentElement,
    useStripe,
    useElements
} from "@stripe/react-stripe-js";
import DialogSports from "../Dialog/Dialog";
import axios from "axios";
import { convertDateToISODate } from "../../helpers/functions";

export default function CheckoutForm(props) {
    const stripe = useStripe();
    const elements = useElements();

    const [message, setMessage] = useState(null);
    const [isLoading, setIsLoading] = useState(false);
    const [dialogProps, setDialogProps] = useState({ title: '', content: '', leftButton: '', rightButton: '', open: false });
    const [canClose, setCanClose] = useState(false);

    // Hear for changes in message
    useEffect(() => {
        if (message) {
            // Set message in dialog
            setDialogProps(
                {
                    title: 'Información ❗️',
                    content: message,
                    leftButton: 'Cerrar',
                    rightButton: '',
                    open: true
                }
            );
        }
    }, [message]);

    // Post-purchase completed
    const postPurchaseSuccess = async (piID) => {
        // Step 1 :: Add sale to DB
        await axios.post(`${process.env.REACT_APP_ENV == 'dev' ? process.env.REACT_APP_BLL_URL : ''}/sale/create`, {
            cart: [props.selectedPack],
            total: props.selectedPack.priceMXN,
            purchaserID: props.logguedInUser._id,
            method: 'card',
            date: new Date(),
            time: new Date(),
            classes: props.selectedPack.classes,
            paymentIntentID: piID
        })
        .then(async (response) => {
            // Step 1 :: Create expiration entry in DB
            // await sendExpirationEntry(props.selectedPack);
            // Step 2 :: Update credits of the logguedInUser in the DB based on the purchase
            await addPackToUserPacks(props.selectedPack);
            // Step 3 :: Inform user about purchase via SMS
            // (props.logguedInUser.phoneVerified) && sendConfirmationSMS(props.logguedInUser.phoneNumber, `[AURA] - Gracias por tu compra de ${props.selectedPack.classes} clases en Aura Studio, tu paquete ha sido agregado a tu Aura card.`);
            setCanClose(true);
        })
        .catch((error) => {
            console.log(error);
        });
    }

    // Send expiration entry
    // const sendExpirationEntry = async (pack) => {
    //     // Ge today
    //     const today = new Date();
    //     // Get notification date
    //     const notificationDate = await today.setDate(today.getDate() + (pack.validityDays - 5));
    //     // Get expiration date
    //     let expirationDate = new Date(today);
    //     expirationDate = await expirationDate.setDate(expirationDate.getDate() + pack.validityDays);

    //     // Get classes promise
    //     const getClassesInPack = () => {
    //         return new Promise((resolve) => {
    //             if (pack) {
    //                 resolve(pack.classes);
    //             }
    //         });
    //     }
    //     // Payload
    //     let payload = {
    //         purchaserID: props.logguedInUser._id,
    //         purchaserPhoneNumber: props.logguedInUser.phoneNumber,
    //         classesInPack: await getClassesInPack(),
    //         notificationDate: notificationDate,
    //         expirationDate: expirationDate,
    //         date: new Date(),
    //     }

    //     // Send expiration entry
    //     await axios.post(`${process.env.REACT_APP_ENV == 'dev' ? process.env.REACT_APP_BLL_URL : ''}/expiration/create`, payload)
    //     .then((response) => {
    //         console.log('Expiration entry created');
    //     })
    //     .catch((error) => {
    //         console.log(error);
    //     });
    // }

    // Send confirmation SMS
    // const sendConfirmationSMS = async (phoneNumber, message) => {
    //     await axios.post(`${process.env.REACT_APP_ENV == 'dev' ? process.env.REACT_APP_BLL_URL : ''}/aws/sendSMS`, { phoneNumber: phoneNumber, message: message })
    //         .then((res) => {
    //             console.log('SMS sent');
    //         })
    //         .catch((err) => {
    //             console.error(err);
    //         }
    //     );
    // }

    // Update credits of the logguedInUser in the DB based on the purchase
    // const updateCredits = async (pack) => {
    //     let creditsType = (props.selectedPack.discipline === 'Pilates Reformer & Sculpt/Tone') ? 'creditsAll' 
    //                     : (props.selectedPack.discipline === 'Pilates Reformer') ? 'creditsPilates' 
    //                     : 'creditsSculpt';

    //     await axios.post(`${process.env.REACT_APP_ENV == 'dev' ? process.env.REACT_APP_BLL_URL : ''}/user/updateUserCredits`, {
    //         ID: props.logguedInUser._id,
    //         credits: pack.classes,
    //         operation: 'add',
    //         creditsType: 'creditsAll'
    //     })
    //     .then((response) => {
    //         console.log('everything went well, thank you');
    //     })
    //     .catch((error) => {
    //         console.log(error);
    //     });
    // }

    // Add pack to user packs array object in DB
    const addPackToUserPacks = async (pack) => {
        // Calculate validity days and define expiration date
        let expirationDate = new Date();
        expirationDate = expirationDate.setDate(expirationDate.getDate() + pack.validityDays);
        expirationDate = convertDateToISODate(expirationDate);
        // Create unique ID for pack
        let packID = `${props.logguedInUser._id}_${pack._id}_${new Date().getTime()}`;
        // Create pack object, add all pack properties + current date + expiration date
        let packToUpload = {
            ...pack,
            date: new Date(),
            expirationDate: expirationDate,
            remainingClasses: pack.classes,
            id: packID
        }
        // Send pack to user packs
        await axios.post(`${process.env.REACT_APP_ENV == 'dev' ? process.env.REACT_APP_BLL_URL : ''}/user/addPack`, {
            userID: props.logguedInUser._id,
            pack: packToUpload
        })
        .then((response) => {
            console.log('Pack added to user packs');
        })
        .catch((error) => {
            console.log(error);
        });
    }
    

    const handleSubmit = async (e) => {
        e.preventDefault();

        if (!stripe || !elements) {
            // Stripe.js hasn't yet loaded.
            // Make sure to disable form submission until Stripe.js has loaded.
            return;
        }

        setIsLoading(true);

        const { error, paymentIntent } = await stripe.confirmPayment({
            elements,
            confirmParams: {
                // Make sure to change this to your payment completion page
                return_url: `${window.location.origin}/completion?user=${props.logguedInUser._id}&pack=${props.selectedPack._id}&phone=${props.logguedInUser.phoneNumber}`,
                receipt_email: props.logguedInUser.email,
            },
            redirect: "if_required"
        });

        
        if (error) {
            if (error.code === 'card_declined') {
                setCanClose(true);
                setMessage('Lo sentimos, tu tarjeta ha sido rechazada, por favor comprueba los datos ingresados o comunícate con tu banco, no se ha realizado ningún cargo.');
            } else {
                setCanClose(true);
                setMessage(error.message);
            }
        } else if (paymentIntent && paymentIntent.status === "succeeded") {
            await postPurchaseSuccess(paymentIntent.id);
            setMessage('Gracias por tu compra 🎉 \n\n Al cerrar esta ventana verás reflejado tu paquete en la sección Mis membresias.');
            setCanClose(true);
        } else {
            setCanClose(true);
            setMessage("An unexpected error occurred.");
        }

        setIsLoading(false);
    };

    const paymentElementOptions = {
        layout: "tabs"
    }

    // Handle Left button of dialog
    const handleDialogLeftButton = () => {
        if (canClose) {
            setDialogProps(
                { 
                    title: '', 
                    content: '', 
                    leftButton: '', 
                    rightButton: '', 
                    open: false
                }
            );
            // If success, close dialog and checkout modal
            if (message.includes('Gracias por tu compra')) {
                // Set local storage variable to indicate we should go back to my wallet after window reload is completed
                localStorage.setItem('purchaseCompleted', true);
                // Reload window
                window.location.reload();
            }
        }
    }

    return (
        <form id="payment-form" onSubmit={handleSubmit}>
            <DialogSports
                dialogProps={dialogProps} 
                setDialogProps={setDialogProps} 
                handleDialogLeftButton={handleDialogLeftButton}
            />
            <PaymentElement id="payment-element" options={paymentElementOptions} />
            <button 
                disabled={isLoading} 
                id="submit"
                style={{
                    backgroundColor: props.darkMode ? '#FFF' : '#000',
                    boxShadow: '0 4px 8px 0 rgba(0, 0, 0, 0.2), 0 6px 20px 0 rgba(0, 0, 0, 0.19)',
                    textTransform: 'none',
                    color: props.darkMode ? '#000' : '#FFF',
                    width: '100%',
                    fontFamily: 'LarkenBold',
                    fontSize: '20px',
                    border: 'none',
                    padding: '8px',
                    marginTop: '40px',
                }}
            >
                <span id="button-text">
                    {isLoading ? <div className="spinner" id="spinner"></div> : "Pagar"}
                </span>
            </button>
        </form>
    );
}