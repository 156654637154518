import React, { useState, useEffect } from "react";
import { Grid, Typography, useTheme, Button, Box, Divider, IconButton, Table, TableBody, TableCell, TableRow, CircularProgress } from "@mui/material";
import CloseIcon from '@mui/icons-material/Close';
import ScheduleCalendar from "../ScheduleCalendar/ScheduleCalendar";
import axios from 'axios';
import CIcon from "@coreui/icons-react";
import * as icon from '@coreui/icons';
import DialogSports from "../Dialog/Dialog";


export default function RegisterToClassContent(props) {
    const [dialogProps, setDialogProps] = useState({ title: '', content: '', leftButton: '', rightButton: '', open: false });
    const [selectedClass, setSelectedClass] = useState(null);
    const [coaches, setCoaches] = useState([]);
    const [registering, setRegistering] = useState(false);
    const [credits, setCredits] = useState(0);
    const theme = useTheme();

    // Scroll to top of pre-register-page component on selectedClass change
    useEffect(() => {
        if (selectedClass) {
            document.getElementById('pre-register-page').scrollIntoView({ behavior: 'smooth' });
        }
    }, [selectedClass]);

    // Get coaches from local storage
    useEffect(() => {
        setCoaches(props.coaches);
    }, [props.coaches]);

    // Hear for changes of selectedClass
    useEffect(() => {
        if (selectedClass) {
            let isAbleToRegister = checkDisciplineCredits();
            if (!isAbleToRegister) {
                setDialogProps({ 
                    title: 'Alerta ⚠️', 
                    content: 'Parece que no tienes clases suficientes para unirte a una clase, por favor ve a la sección de Membresías para adquirir una e inténtalo de nuevo.', 
                    leftButton: '', 
                    rightButton: 'Ok', 
                    open: true
                });
            }
        }
    }, [selectedClass]);

    // Check if user has sufficient discipline credits for the discipline of the selected class
    const checkDisciplineCredits = () => {
        // Sum all the remainingClasses objects from each one of the objects inside of the packs array of the user to determine the total amount of credits
        let credits = 0;
        let user = props.logguedInUser;
        user.packs.forEach(pack => {
            credits += pack.remainingClasses;
        });
        setCredits(credits);
        return credits > 0;
    }

    // Handle Left button of dialog
    const handleDialogRightButton = () => {
        setDialogProps(
            { 
                title: '', 
                content: '', 
                leftButton: '', 
                rightButton: '', 
                open: false
            }
        );
        setSelectedClass(null);
        (registering) && localStorage.setItem('registerCompleted', true);
        (registering) && window.location.reload();
    }

    // Handle modal close
    const handleClose = () => {
        props.setRegisterToClass(false);
    };

    // Update user reservations in DB
    const updateUser = async (userID, packs) => {
        await axios.post(`${process.env.REACT_APP_ENV == 'dev' ? process.env.REACT_APP_BLL_URL : ''}/user/update`, { _id: userID, packs: packs })
            .then((res) => {
                console.log('Reservations updated in DB');
            })
            .catch((err) => {
                console.error(err);
            });
    }

    // Send confirmation SMS
    // const sendConfirmationSMS = async (phoneNumber, message) => {
    //     await axios.post(`${process.env.REACT_APP_ENV == 'dev' ? process.env.REACT_APP_BLL_URL : ''}/aws/sendSMS`, { phoneNumber: phoneNumber, message: message })
    //         .then((res) => {
    //             console.log('SMS sent');
    //         })
    //         .catch((err) => {
    //             console.error(err);
    //         }
    //     );
    // }

    // Update available classes of most recent expirationDate pack from user
    const updateUserDetails = async () => {
        let user = props.logguedInUser;
        // First filter packs from user that have remainingClasses > 0
        let packs = user.packs.filter(pack => pack.remainingClasses > 0);
        // Then sort them by expirationDate
        packs.sort((a, b) => new Date(a.expirationDate) - new Date(b.expirationDate));
        // Get the most recent expirationDate pack
        let mostRecentPack = packs[0];
        // Update remainingClasses of most recent pack
        mostRecentPack.remainingClasses -= 1;
        // Update user with updated pack
        user.packs = await user.packs.map(pack => pack.id === mostRecentPack.id ? mostRecentPack : pack);
        // Return updated user
        return user;
    }

    // Handle register to class
    const registerToClass = async (e) => {
        e.preventDefault();

        setRegistering(true);
        
        // Get updated user
        let updatedUser = await updateUserDetails();


        let userID = props.logguedInUser._id;
        let classID = selectedClass._id;

        // Request to update class participants
        await axios.post(`${process.env.REACT_APP_ENV == 'dev' ? process.env.REACT_APP_BLL_URL : ''}/schedule/updateClassParticipants`, { classID: classID, newParticipant: userID })
            .then(async (res) => {
                let newReservations = props.logguedInUser.reservations.concat(classID);
                // Update local storage
                updatedUser.reservations = newReservations;
                localStorage.setItem('LoggedInUser', JSON.stringify(updatedUser));

                // Update user database tables
                await updateUser(userID, updatedUser.packs);

                // Send confirmation SMS - To be implemented in the future due to SMS costs

                //let message = `[AURA] - ¡Hola! Te has registrado a la clase ${selectedClass.classTitle} el día ${formatDate(selectedClass.date)} a la(s) ${getFormattedTime(selectedClass.time)}. Nos vemos en la sesión.`;
                //sendConfirmationSMS(props.logguedInUser.phoneNumber, message);

                // Update state
                props.setLogguedInUser(updatedUser);
                props.refreshUserData(JSON.stringify(updatedUser));

                // Set dialog
                setDialogProps({ 
                    title: '¡Éxito! 🎉', 
                    content: 'Te has registrado a la clase exitosamente, te esperamos en la sesión.', 
                    leftButton: '', 
                    rightButton: 'Ok', 
                    open: true
                });
            })
            .catch((err) => {
                console.log(err);
            });
    }

    // Get coach name
    const getCoachName = (coachID) => {
        let coach = coaches.find(coach => coach._id === coachID);
        return `${coach.firstName} ${coach.lastName}`;
    }

    // Format date function
    const formatDate = (inputDate) => {
        // Parse the input date string to a Date object
        const date = new Date(inputDate);

        // Adjust the date to the local timezone
        const localDate = new Date(date.getTime() + date.getTimezoneOffset());

        // Extract the day, month, and year
        const day = String(localDate.getDate()).padStart(2, '0'); // padStart ensures 2 digits
        const month = String(localDate.getMonth() + 1).padStart(2, '0'); // getMonth is 0-based
        const year = localDate.getFullYear();

        // Construct the output date string
        return `${day}/${month}/${year}`;
    }

    // Convert ISO Date to local Date
    const convertISODateToLocalDate = (isoDateString) => {
        const date = new Date(isoDateString);
        const localDate = new Date(date.getTime() + date.getTimezoneOffset());
        return localDate;
    }

    // Get AM or PM time
    const getFormattedTime = (time) => {
        time = convertISODateToLocalDate(new Date(time));
        let hours = time.getHours();
        let minutes = time.getMinutes();
        let ampm = hours >= 12 ? 'PM' : 'AM';
        hours = hours % 12;
        hours = hours ? hours : 12;
        minutes = minutes < 10 ? '0' + minutes : minutes;
        let strTime = hours + ':' + minutes + ' ' + ampm;
        return strTime;
    }
            
    return (
            <>
                <DialogSports dialogProps={dialogProps} setDialogProps={setDialogProps} handleDialogRightButton={handleDialogRightButton}/>
                <Grid 
                    container 
                    spacing={0} 
                    direction='column'
                >
                    {/* Title and close button */}
                    <Grid 
                        item 
                        xs={1} 
                        sx={{
                            position: 'fixed', 
                            backgroundColor: theme.palette.background.default, 
                            zIndex: 1, 
                            width: '85vw', 
                            pt: 1, 
                        }}
                    >
                        <Grid container direction={'row'}>
                            <Grid item xs>
                                <Typography variant="h6">Únete a una clase</Typography>
                            </Grid>
                            <Grid item>
                                <IconButton variant="contained" onClick={handleClose}>
                                    <CloseIcon />
                                </IconButton>
                            </Grid>
                        </Grid>
                        <Divider sx={{pt: 0.5}}/>
                    </Grid>
                    {/* Participants */}
                    <Grid item xs={11} sx={{mt: 5}}>
                        {
                            selectedClass === null 
                            ?
                            /* If no selectedClass, then show calendar to pick class */
                            <Box>
                                <ScheduleCalendar 
                                    setSelectedClass={setSelectedClass} 
                                    coaches={props.coaches} 
                                    logguedInUser={props.logguedInUser}
                                />
                            </Box>
                            :
                            /* If selectedClass, then show class pre-register page */
                            <Box id='pre-register-page'>
                                <Button onClick={() => setSelectedClass(null)}>
                                    <CIcon 
                                        icon={icon.cilArrowLeft} 
                                        className='text-primary' 
                                        style={{height: '20px', marginRight: '20px'}}
                                    />
                                    <Typography sx={{textTransform: 'none'}}>Calendario</Typography>
                                </Button>
                                <Grid container direction='column'>
                                    <Grid item xs={1} textAlign={'center'} sx={{m: 2}}>
                                        <Typography variant="h6">Pre-registro</Typography>
                                    </Grid>
                                    <Grid item xs={9}>
                                        <Table>
                                            <TableBody>
                                                <TableRow>
                                                    <TableCell>Título</TableCell>
                                                    <TableCell>{selectedClass.classTitle}</TableCell>
                                                </TableRow>
                                                <TableRow>
                                                    <TableCell>Coach</TableCell>
                                                    <TableCell>{getCoachName(selectedClass.teacherID)}</TableCell>
                                                </TableRow>
                                                <TableRow>
                                                    <TableCell>Fecha</TableCell>
                                                    <TableCell>{formatDate(selectedClass.date)}</TableCell>
                                                </TableRow>
                                                <TableRow>
                                                    <TableCell>Horario</TableCell>
                                                    <TableCell>{getFormattedTime(selectedClass.time)} - {getFormattedTime(selectedClass.endTime)}</TableCell>
                                                </TableRow>
                                                <TableRow>
                                                    <TableCell>Descripción</TableCell>
                                                    <TableCell>{selectedClass.classDescription}</TableCell>
                                                </TableRow>
                                            </TableBody>
                                        </Table>
                                    </Grid>
                                    <Grid item xs={1} textAlign={'center'} sx={{m:2}}>
                                        <Typography >{`Clases disponibles: ${credits}`}</Typography>
                                        <Typography >{`Costo de la clase: 1`}</Typography>
                                        <Typography >{`Clases después de registrarte: ${credits - 1}`}</Typography>
                                        <Typography >{`¿Deseas registrarte a esta clase?`}</Typography>
                                    </Grid>
                                    <Grid item xs={1} textAlign='center'>
                                        {
                                            registering ?
                                            <CircularProgress size={80} sx={{margin: 5}}/>
                                            :
                                            <Button 
                                                disabled={credits == 0} 
                                                onClick={(e) => registerToClass(e)} 
                                                variant='contained'
                                                sx={{
                                                    color: 'white',
                                                    textTransform: 'none',
                                                    p: 0.75,
                                                    pl: 2,
                                                    pr: 2,
                                                    fontFamily: 'LarkenBold'
                                                }}
                                            >Registrarme</Button>
                                        }
                                        
                                    </Grid>
                                    <Grid item xs={1} textAlign={'center'}>
                                        <Typography sx={{fontSize: '10px', m: 3}}>
                                            Al presionar el botón "REGISTRARME" aceptas que se descontará la cantidad de 1 clase de tu membresía más próxima a expirar, revisa nuestras políticas de uso para más información
                                        </Typography>
                                    </Grid>
                                </Grid>
                            </Box>
                        }
                    </Grid>
                </Grid>
            </>
    )
}