import React, { useState, useEffect } from "react";
import { 
    Grid, 
    Typography, 
    Grow, 
    useTheme, 
    Box,
    Paper,
    IconButton
} from "@mui/material";
import { convertISODateToDDMMYYYY } from "../../helpers/functions";
import { Edit } from "@mui/icons-material";

export default function MembershipCard (props) {
    // Use theme
    const theme = useTheme();

    // RETURN
    return (
        <Grid item xs={6}>
            <Grow in={true} style={{ transitionDelay: `${50 * props.index}ms` }}>
                <Paper elevation={6}>
                    <Grid container direction='column' sx={{opacity: props.pack.remainingClasses === 0 ? 0.4 : 1}} p={0.5}>
                        <Grid item xs>
                            {/* Classes in pack */}
                            <Grid container direction='row'>
                                <Grid item xs={10}>
                                    <Box textAlign={'initial'}>
                                        <Typography
                                            sx={{
                                                fontSize: '40px',
                                                textAlign: 'left',
                                                fontFamily: 'InterRegular',
                                                fontWeight: 'bold',
                                                display: 'inline',
                                                color: theme.palette.text.primary,
                                            }}
                                        >
                                            {props.pack.classes === 999 ? '∞' : props.pack.classes}
                                        </Typography>
                                        <Typography
                                            sx={{
                                                fontSize: '20px',
                                                textAlign: 'left',
                                                fontFamily: 'LarkenRegular',
                                                display: 'inline',
                                                color: theme.palette.text.primary,
                                            }}
                                        >
                                            {` clases`}
                                        </Typography>
                                    </Box>
                                </Grid>
                                <Grid item xs={2}>
                                    {
                                        props.admin &&
                                        <IconButton onClick={() => props.setSelectedPack(props.pack)}>
                                            <Edit/>
                                        </IconButton>
                                    }
                                </Grid>
                            </Grid>
                            {/* Class amount and expiration */}
                            <Typography
                                sx={{
                                    fontSize: '16px',
                                    textAlign: 'left',
                                    opacity: 0.7,
                                    pt: 0.5
                                }}
                            >
                                {`Membresía`}
                            </Typography>
                        </Grid>
                        <Grid item xs textAlign={'left'}>
                            <Typography 
                                sx={{
                                    fontSize: '16px', 
                                    fontFamily: 'LarkenBold',
                                    color: theme.palette.primary.main,
                                    fontWeight: 'bold',
                                    pt: 1
                                }}
                            >
                            {`${props.pack.classes === 999 ? '∞' : props.pack.remainingClasses} clases restantes`}</Typography>
                        </Grid>
                        <Grid item xs mt={1}>
                            <Grid container direction={'row'}>
                                <Grid item xs={2}>
                                    <Typography sx={{fontSize: '14px'}}>{'Expira'}</Typography>
                                </Grid>
                                <Grid item xs={10} textAlign={'end'}>
                                    <Typography>{`${convertISODateToDDMMYYYY(props.pack.expirationDate)}`}</Typography>
                                </Grid>
                            </Grid>
                        </Grid>
                    </Grid>
                </Paper>
            </Grow>
        </Grid>
    )
}